import router from "../../../router";
import axios from "axios";

export default {
    loadTransactionList({ commit, dispatch }, payload)
    {
        return new Promise((resolve, reject) => {
            let filter_fields = this.getters['transactions/getTransactionFilterFields'];
            let main_filter = this.getters['transactions/getTransactionMainFilter'];

            if (!payload['preloader_disable'] || payload['preloader_disable'] == false) {
                dispatch('pagePreloader/setPagePreloaderIsActive', true, {root: true});

                commit('setTransactionList', []);
            }

            if (!main_filter && router.currentRoute.query.main_filter) {
                main_filter = router.currentRoute.query.main_filter;

                commit('setTransactionFilterFieldsMutation', {main_filter: router.currentRoute.query.main_filter});
            }

            if (!filter_fields.transactionNumber && router.currentRoute.query.transaction_number) {
                filter_fields.transactionNumber = router.currentRoute.query.transaction_number;

                commit('setTransactionFilterFieldsMutation', {field_name: 'transactionNumber', field_value: router.currentRoute.query.transaction_number})
            }

            if (!filter_fields.transactionCreatedAt && router.currentRoute.query.transaction_created_at) {
                filter_fields.transactionCreatedAt = router.currentRoute.query.transaction_created_at;

                commit('setTransactionFilterFieldsMutation', {field_name: 'transactionCreatedAt', field_value: router.currentRoute.query.transaction_created_at})
            }

            if (!filter_fields.transactionAmount && router.currentRoute.query.transaction_amount) {
                filter_fields.transactionAmount = router.currentRoute.query.transaction_amount;

                commit('setTransactionFilterFieldsMutation', {field_name: 'transactionAmount', field_value: router.currentRoute.query.transaction_amount})
            }

            if (!filter_fields.transactionType && router.currentRoute.query.transaction_type) {
                filter_fields.transactionType = router.currentRoute.query.transaction_type;

                commit('setTransactionFilterFieldsMutation', {field_name: 'transactionType', field_value: router.currentRoute.query.transaction_type})
            }

            if (!filter_fields.transactionArticle && router.currentRoute.query.transaction_article) {
                filter_fields.transactionArticle = router.currentRoute.query.transaction_article;

                commit('setTransactionFilterFieldsMutation', {field_name: 'transactionArticle', field_value: router.currentRoute.query.transaction_article})
            }

            if (!filter_fields.transactionStatus && router.currentRoute.query.transaction_status) {
                filter_fields.transactionStatus = router.currentRoute.query.transaction_status;

                commit('setTransactionFilterFieldsMutation', {field_name: 'transactionStatus', field_value: router.currentRoute.query.transaction_status})
            }

            if (!filter_fields.filterStartDate && router.currentRoute.query.filter_start_date) {
                filter_fields.filterStartDate = router.currentRoute.query.filter_start_date;

                commit('setTransactionFilterFieldsMutation', {field_name: 'filterStartDate', field_value: router.currentRoute.query.filter_start_date})
            }

            if (!filter_fields.filterEndDate && router.currentRoute.query.filter_end_date) {
                filter_fields.filterEndDate = router.currentRoute.query.filter_end_date;

                commit('setTransactionFilterFieldsMutation', {field_name: 'filterEndDate', field_value: router.currentRoute.query.filter_end_date})
            }

            payload['filter_fields'] = filter_fields;
            payload['main_filter'] = main_filter;

            axios.post('/api/wallet/transaction-list', payload).then((response) => {
                const result = response.data.data;

                if (response.data.data.code != 'ok')
                {
                    resolve({
                        error: true,
                        error_msg: 'Server response code not ok!',
                    });

                    return;
                }

                if (!response.data.data.result) {
                    resolve({
                        error: true,
                        error_msg: 'Result is empty!',
                    });

                    return;
                }

                dispatch('pagePreloader/setPagePreloaderIsActive', false, {root: true});

                commit('setTransactionList', response.data.data.result.transactions);
                commit('setTransactionListCount', response.data.data.result.transactions_count);

                resolve(true);
            }).catch((error) => {
                reject(false);
            });
        });
    },
    setTransactionFilterFields({ commit }, payload)
    {
        commit('setTransactionFilterFieldsMutation', payload);
    },
    setSupplementAmount({ commit }, payload)
    {
        commit('setSupplementAmount', payload);
    },
}
