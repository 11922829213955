export default {
    getDocumentList: (state) => {
        return state.documentList;
    },
    getDocumentListCount: (state) => {
        return state.documentListCount;
    },
    getDocumentMainFilter: (state) => {
        return state.documentFilter.main_filter;
    },
    getDocumentFilterFields: (state) => {
        return state.documentFilter.fields;
    },
    getDocumentModalOpen: (state) => {
        return state.documentModalOpen;
    },
    getDocumentIdAndDocumentIndex: (state) => {
        return {
            document_id: state.document_id,
            document_index: state.document_index
        };
    },
}
