import {mapGetters} from "vuex";
import axios from "axios";
import store from "@/store";

export default {
    name: 'InformationModal',

    data() {
        return {
            accept_privacy: false,
            accept_rules: false,
            signed_privacy: false,
            signed_rules: false,
        }
    },
    computed: {
        ...mapGetters({
            getUser: 'currentUser/getUser',
        }),
    },
    methods: {
        showAcceptPrivacyModal() {
            const app = this;

            app.$refs['modal-accept-privacy'].show();
        },
        checkAcceptPrivacy() {
            const app = this;

            app.$store.dispatch('currentUser/initUser');
        },
        hideAcceptPrivacyModal() {
            const app = this;

            app.accept_privacy = false;

            app.$refs['modal-accept-privacy'].hide();
        },
        acceptPrivacy() {
            const app = this;

            if (app.accept_privacy == false) {
                alert('Необходимо принять соглашение на обработку персональных данных!');

                return;
            }

            axios.post('/api/accept-privacy', {
                accept_privacy: app.accept_privacy,
            }).then(response => {
                const result = response.data.data;

                if (result.code === 'ok' && result.result.success === true) {
                    app.checkAcceptPrivacy();

                    app.$toaster.success('Соглашение успешно принято');
                } else {
                    app.$toaster.error('Ошибка отправки соглашения');
                }
            }).catch(error => {
                console.log(error);

                app.$toaster.error('Ошибка отправки соглашения');
            });
        },

        showAcceptRulesModal() {
            const app = this;

            app.$refs['modal-accept-rules'].show();
        },
        checkAcceptRules() {
            const app = this;

            app.$store.dispatch('currentUser/initUser');
        },
        hideAcceptRulesModal() {
            const app = this;

            app.accept_privacy = false;

            app.$refs['modal-accept-rules'].hide();
        },
        acceptRules() {
            const app = this;

            if (app.accept_rules == false) {
                alert('Необходимо принять соглашение с правилами!');

                return;
            }

            axios.post('/api/accept-rules', {
                accept_rules: app.accept_rules,
            }).then(response => {
                const result = response.data.data;

                if (result.code === 'ok' && result.result.success === true) {
                    app.checkAcceptRules();

                    app.$toaster.success('Соглашение успешно принято');
                } else {
                    app.$toaster.error('Ошибка отправки соглашения');
                }
            }).catch(error => {
                console.log(error);

                app.$toaster.error('Ошибка отправки соглашения');
            });
        },

        showSignedPrivacyModal() {
            const app = this;

            app.$refs['modal-signed-privacy'].show();
        },
        checkSignedPrivacy() {
            const app = this;

            app.$store.dispatch('currentUser/initUser');
        },
        hideSignedPrivacyModal() {
            const app = this;

            app.accept_privacy = false;

            app.$refs['modal-signed-privacy'].hide();
        },
        signPrivacy() {
            const app = this;

            if (app.signed_privacy == false) {
                alert('Необходимо принять соглашение с обрабаоткой персональных данных!');

                return;
            }

            axios.post('/api/sign-privacy', {
                signed_privacy: app.signed_privacy,
            }).then(response => {
                const result = response.data.data;

                if (result.code === 'ok' && result.result.success === true) {
                    app.checkSignedPrivacy();

                    app.$toaster.success('Соглашение успешно принято');
                } else {
                    app.$toaster.error('Ошибка отправки соглашения');
                }
            }).catch(error => {
                console.log(error);

                app.$toaster.error('Ошибка отправки соглашения');
            });
        },

        showSignedRulesModal() {
            const app = this;

            app.$refs['modal-signed-rules'].show();
        },
        checkSignedRules() {
            const app = this;

            app.$store.dispatch('currentUser/initUser');
        },
        hideSignedRulesModal() {
            const app = this;

            app.signed_privacy = false;

            app.$refs['modal-signed-rules'].hide();
        },
        signRules() {
            const app = this;

            if (app.signed_rules == false) {
                alert('Необходимо принять соглашение на подписание заявления о присоединении к Правилам дистанционного обслуживания клиентов!');

                return;
            }

            axios.post('/api/sign-rules', {
                signed_rules: app.signed_rules,
            }).then(response => {
                const result = response.data.data;

                if (result.code === 'ok' && result.result.success === true) {
                    app.checkSignedRules();

                    app.$toaster.success('Соглашение успешно принято');
                } else {
                    app.$toaster.error('Ошибка отправки соглашения');
                }
            }).catch(error => {
                console.log(error);

                app.$toaster.error('Ошибка отправки соглашения');
            });
        },
    },
    watch: {
        getUser: {
            immediate: true,
            deep: true,
            handler (newValue, oldValue) {
                if (newValue) {
                    const app = this;

                    if (newValue.accept_privacy == false) {
                        app.showAcceptPrivacyModal();
                    } else {
                        app.hideAcceptPrivacyModal();
                    }

                    if (newValue.accept_rules == null) {
                        app.showAcceptRulesModal();
                    } else {
                        app.hideAcceptRulesModal();
                    }

                    if (newValue.signed_privacy == null) {
                        app.showSignedPrivacyModal();
                    } else {
                        app.hideSignedPrivacyModal();
                    }

                    if (newValue.signed_rules == null && newValue.accept_rules) {
                        app.showSignedRulesModal();
                    } else {
                        app.hideSignedRulesModal();
                    }
                }
            }
        },
    }
}
