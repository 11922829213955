import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    transactionList: [],
    transactionListCount: null,
    transactionFilter: {
        main_filter: null,
        fields: {
            transactionNumber: null,
            transactionCreatedAt: null,
            transactionAmount: null,
            transactionType: null,
            transactionArticle: null,
            transactionStatus: null,
            filterEndDate: null,
            filterStartDate: null,
        },
    },
    supplement_amount: null,
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
}
