import router from "../../../router";
import axios from "axios";

export default {
    loadTicketList({ commit }, payload)
    {
        return new Promise((resolve, reject) => {
            let filter_fields = this.getters['tickets/getTicketFilterFields'];
            let main_filter = this.getters['tickets/getTicketMainFilter'];

            if (!main_filter && router.currentRoute.query.main_filter) {
                main_filter = router.currentRoute.query.main_filter;

                commit('setTicketFilterFieldsMutation', {main_filter: router.currentRoute.query.main_filter});
            }

            if (!filter_fields.ticketNumber && router.currentRoute.query.ticket_number) {
                filter_fields.ticketNumber = router.currentRoute.query.ticket_number;

                commit('setTicketFilterFieldMutation', {field_name: 'ticketNumber', field_value: router.currentRoute.query.ticket_number})
            }

            if (!filter_fields.ticketTitle && router.currentRoute.query.ticket_title) {
                filter_fields.ticketTitle = router.currentRoute.query.ticket_title;

                commit('setTicketFilterFieldMutation', {field_name: 'ticketTitle', field_value: router.currentRoute.query.ticket_title})
            }

            if (!filter_fields.ticketDescription && router.currentRoute.query.ticket_description) {
                filter_fields.ticketDescription = router.currentRoute.query.ticket_description;

                commit('setTicketFilterFieldMutation', {field_name: 'ticketDescription', field_value: router.currentRoute.query.ticket_description})
            }

            if (!filter_fields.ticketClosed && router.currentRoute.query.ticket_closed) {
                filter_fields.ticketClosed = router.currentRoute.query.ticket_closed;

                commit('setTicketFilterFieldMutation', {field_name: 'ticketClosed', field_value: router.currentRoute.query.ticket_closed})
            }

            payload['filter_fields'] = filter_fields;
            payload['main_filter'] = main_filter;

            axios.post('/api/ticket/ticket-list', payload).then((response) => {
                const result = response.data.data;

                if (response.data.data.code != 'ok')
                {
                    resolve({
                        error: true,
                        error_msg: 'Server response code not ok!',
                    });

                    return;
                }

                if (!response.data.data.result) {
                    resolve({
                        error: true,
                        error_msg: 'Result is empty!',
                    });

                    return;
                }

                commit('setTicketList', response.data.data.result.tickets);
                commit('setTicketListCount', response.data.data.result.tickets_count);

                resolve(true);
            }).catch((error) => {
                reject(false);
            });
        });
    },
    setTicketFilterFields({ commit }, payload)
    {
        commit('setTicketFilterFieldsMutation', payload);
    },
    deleteTickets({ commit }, payload)
    {
        return new Promise((resolve, reject) => {
            axios.post('/api/ticket/delete-tickets', {
                ids: payload
            }).then(response => {
                const result = response.data.data;

                if (response.data.data.result.success !== true) {
                    resolve(false);
                }

                commit('deleteTicketsMutation', payload);

                resolve(true);
            }).catch(error => {
                reject(error);
            });
        });
    },
    setTicketModalOpen({commit}, payload)
    {
        commit('setTicketModalOpenMutation', payload);
    },
    setTicketIdAndTicketIndexToModalForm({commit}, payload)
    {
        commit('setTicketIdAndTicketIndexToModalFormMutation', payload);
    },
    addTicketToTicketList({commit}, payload)
    {
        commit('addTicketToTicketList', payload);
    },
    updateTicket({ commit }, payload)
    {
        commit('updateTicket', payload);
    },
    setStatusTicket({commit}, payload)
    {
        commit('setStatusTicket', payload);
    },
    setStatuses({commit}, payload)
    {
        commit('setStatuses', payload);
    },
}
