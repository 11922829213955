import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    clientList: [],
    clientListCount: null,
    clientFilter: {
        main_filter: null,
        fields: {
            clientNumber: null,
            clientTitle: null,
            clientPhoneNumber: null,
            clientDeleted: null,
        },
    },
    clientModalOpen: false,
    client_id: null,
    client_index: null,
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
}
