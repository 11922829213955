export default {
    getClientList: (state) => {
        return state.clientList;
    },
    getClientListCount: (state) => {
        return state.clientListCount;
    },
    getClientMainFilter: (state) => {
        return state.clientFilter.main_filter;
    },
    getClientFilterFields: (state) => {
        return state.clientFilter.fields;
    },
    getClientModalOpen: (state) => {
        return state.clientModalOpen;
    },
    getClientIdAndClientIndex: (state) => {
        return {
            client_id: state.client_id,
            client_index: state.client_index
        };
    },
}
