export default {
    setIsOpen(state, payload) {
        state.isOpen = payload;
    },
    setMessengerFilterFieldsMutation(state, payload)
    {
        state.messengerFilter.fields.userNickname = payload.user_nickname;
        state.messengerFilter.main_filter = payload.main_filter;
    },
    setMessengerFilterFieldMutation(state, payload)
    {
        if (state.messengerFilter.fields[payload.field_name]) {
            state.messengerFilter.fields[payload.field_name] = payload.field_value;
        }
    },
    setUserList(state, payload)
    {
        state.userList = payload;
    },
    setChatList(state, payload)
    {
        state.chatList = payload;
    },
    setActivePreloader(state, payload)
    {
        state.activePreloader = payload;
    },
}
