import axios from "axios";

export default {
    loadUserStatistics({ commit }, payload)
    {
        return new Promise((resolve, reject) => {
            axios.post('/api/statistics/load-user-statistics', payload).then((response) => {
                const result = response.data.data;

                if (response.data.data.code != 'ok')
                {
                    resolve({
                        error: true,
                        error_msg: 'Server response code not ok!',
                    });

                    return;
                }

                if (!response.data.data.result) {
                    resolve({
                        error: true,
                        error_msg: 'Result is empty!',
                    });

                    return;
                }

                commit('setUserDocumentsStatistics', response.data.data.result.documents_statistic);

                resolve(true);
            }).catch((error) => {
                reject(error);
            });
        });
    },
    setStatisticFilter({ commit }, payload)
    {
        return new Promise((resolve, reject) => {
            axios.post('/api/statistics/set-statistic-filter', payload).then((response) => {
                const result = response.data.data;

                if (response.data.data.code != 'ok')
                {
                    resolve({
                        error: true,
                        error_msg: 'Server response code not ok!',
                    });

                    return;
                }

                if (!response.data.data.result) {
                    resolve({
                        error: true,
                        error_msg: 'Result is empty!',
                    });

                    return;
                }

                commit('setUserDocumentsStatistics', response.data.data.result.documents_statistic);

                resolve(true);
            }).catch((error) => {
                reject(error);
            });
        });
    },
}
