export default {
    getUser: (state) => {
        return state.user;
    },
    getUserBalance: (state) => {
        let result = 0;

        if (state.user) {
            if (state.user.wallet) {
                if (state.user.wallet.balance) {
                    result = state.user.wallet.balance;
                }
            }
        }

        return result;
    },
    getUserVerify: (state) => {
        let result = 0;

        if (state.user) {
            if (state.user.is_verify) {
                result = state.user.is_verify;
            }
        }

        return result;
    },
    getNewNitificationList: (state) => {
        return state.notifications;
    },
    getNotificationCounter: (state) => {
        return state.notificationCounter;
    },
    getRequestConnectionModalOpen: (state) => {
        return state.requestConnectionModalOpen;
    },
}
