import router from "../../../router";
import axios from "axios";

export default {
    setIsOpen({ commit }, payload)
    {
        commit('setIsOpen', payload);
    },
    setMessengerFilterFields({ commit }, payload)
    {
        commit('setMessengerFilterFieldsMutation', payload);
    },
    setUserList({ commit }, payload)
    {
        commit('setUserList', payload);
    },
    loadUserList({ commit }, payload)
    {
        return new Promise((resolve, reject) => {
            let filter_fields = this.getters['messanger/getMessengerFilterFields'];
            let main_filter = this.getters['messanger/getMessengerMainFilter'];

            if (!main_filter && router.currentRoute.query.main_filter) {
                main_filter = router.currentRoute.query.main_filter;

                commit('setMessengerFilterFieldMutation', {main_filter: router.currentRoute.query.main_filter});
            }

            if (!filter_fields.userNickname && router.currentRoute.query.user_nickname) {
                filter_fields.userNickname = router.currentRoute.query.user_nickname;

                commit('setMessengerFilterFieldMutation', {field_name: 'userNickname', field_value: router.currentRoute.query.user_nickname})
            }

            payload['filter_fields'] = filter_fields;
            payload['main_filter'] = main_filter;

            axios.post('/api/messenger/user-list', payload).then((response) => {
                const result = response.data.data;

                if (response.data.data.code != 'ok')
                {
                    resolve({
                        error: true,
                        error_msg: 'Server response code not ok!',
                    });

                    return;
                }

                if (!response.data.data.result) {
                    resolve({
                        error: true,
                        error_msg: 'Result is empty!',
                    });

                    return;
                }

                commit('setUserList', response.data.data.result.users);

                resolve(true);
            }).catch((error) => {
                reject(false);
            });
        });
    },
    setChatList({ commit }, payload)
    {
        commit('setChatList', payload);
    },
    setActivePreloader({ commit }, payload)
    {
        commit('setActivePreloader', payload);
    },
}
