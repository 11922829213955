import axios from "axios";
import router from "../../../router";

export default {
    setUser({ commit }, payload)
    {
        commit('setUserMutation', payload);
    },
    initUser({commit, dispatch})
    {
        return new Promise((resolve, reject) => {
            let host = null;

            const partsHost = window.location.host.split('.');

            if (partsHost.length == 3) {
                host = partsHost[0];
            }

            axios.post('/api/user')
                .then((response) => {

                    if (response.data.data.code != 'ok') {
                        localStorage.removeItem('token');
                        window.location.href = '/login';

                        return;
                    }

                    if (!response.data.data.result.user.enabled) {
                        localStorage.removeItem('token');
                        window.location.href = '/login';

                        return;
                    }

                    commit('setUserMutation', response.data.data.result.user);

                    if (response.data.data.result.settings) {
                        if (response.data.data.result.settings.tariff_list && response.data.data.result.settings.tariff_list.length) {
                            dispatch('system/setTariffSettings', response.data.data.result.settings.tariff_list, {root: true});
                        }
                    }

                    resolve(true);
                })
                .catch(error => {
                    console.log(error);

                    commit('setUserMutation', null);

                    localStorage.removeItem('token');
                    window.location.href = '/login';

                    reject(error);
                    }
                );
        });
    },
    updateUser({ commit }, payload)
    {
        commit('updateUser', payload);
    },
    setUserTariff({ commit }, payload)
    {
        commit('setUserTariff', payload);
    },
    setUserWalletBalance({ commit }, payload)
    {
        commit('setUserWalletBalance', payload);
    },
    setUserWalletSignsBox({ commit }, payload)
    {
        commit('setUserWalletSignsBox', payload);
    },
    setNotificationList({ commit }, payload)
    {
        commit('setNotificationList', payload);
    },
    getNewNotificationCount({ commit }, payload)
    {
        axios.post('/api/notification/get-new-notification-count').then(response => {
            const result = response.data.data;

            if (result.code === 'ok' && result.result.success === true) {
                if (result.result.count || result.result.count == 0) {
                    commit('setNotificationCounter', result.result.count);
                }
            }
        });
    },
    setRequestConnectionModalOpen({ commit }, payload)
    {
        commit('setRequestConnectionModalOpenMutation', payload);
    },
    setCompaniesCount({ commit }, payload)
    {
        commit('setCompaniesCountMutation', payload);
    },
}
