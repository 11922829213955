<template>
    <div class="messenger">
        <div v-if="getIsOpen !== true" class="messenger__btn" @click="toggleMessenger(true)">
            <div class="messenger__btn_icon">

            </div>
        </div>
        <div v-if="getIsOpen === true" class="chats messenger__wr">
            <div class="messenger__in">
                <div class="messenger__header">
                    <div v-if="currentUserChat.user && currentUserChat.user.id || showUserList" class="messenger__header_btn_back_btn" @click="backToChats"></div>
                    <div v-if="currentUserChat.user && currentUserChat.user.id" class="messenger__header-user_avatar">
                        <img v-if="currentUserChat.user.avatar" :src="currentUserChat.user.avatar" />
                        <img v-else src="/images/personal.svg" />
                    </div>
                    <div v-if="currentUserChat.user && currentUserChat.user.id" class="messenger__header-user_nickname">
                        {{ currentUserChat.user.user_name }}
                    </div>
                    <div v-else class="messenger__filter-input">
                        <input
                                v-model="userNickname"
                                placeholder="Найти пользователя"
                                @keyup="acceptFilter"
                        />
                        <!--div class="messenger__filter-label" @click="acceptFilter">
                            <img src="/images/search3.svg" />
                        </div-->
                    </div>
                    <div class="messenger__header_btns">
                        <div class="messenger__header_btn messenger__header_btn_close">
                            <div class="messenger__header_btn_close_btn" @click="toggleMessenger(false)"></div>
                        </div>
                    </div>
                </div>
                <div class="messenger__body">
                    <div v-if="!showUserList && !currentUserChat.user" class="messenger__body-chat_list">
                        <div v-if="getChatList" v-for="(chat, i) in getChatList" class="messenger__body-chat" @click="getChat(chat.user_id)">
                            <div class="messenger__body-avatar_icon">
                                <div class="messenger__body-avatar_icon-img">
                                    <img v-if="chat.avatar" :src="chat.avatar" />
                                    <img v-else src="/images/personal.svg">
                                </div>
                                <div v-if="chat.last_message_status == 'delivered'" class="messenger__body-avatar_icon-circle"></div>
                            </div>
                            <div class="messenger__body-user_info">
                                <div class="messenger__body-user_info-name">
                                    {{ chat.surname }} {{ chat.name }} {{ chat.patronymic }}
                                </div>
                                <div class="messenger__body-user_info-last-msg">
                                    {{ chat.last_message }}
                                </div>
                            </div>
                        </div>
                        <div v-else v-for="(user, i) in getUserList" class="messenger__body-chat" @click="getChat(user.id)">
                            <div class="messenger__body-avatar_icon">
                                <img v-if="user.avatar" :src="user.avatar" />
                                <img v-else src="/images/personal.svg">
                            </div>
                            <div class="messenger__body-user_info">
                                <div class="messenger__body-user_info-name">
                                    {{ user.surname }} {{ user.name }} {{ user.patronymic }}
                                </div>
                                <div class="messenger__body-user_info-last-msg">

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="messenger__search_result" v-if="showUserList">
                        <div v-if="!getUserList.length && showUserList" class="messenger__body-user-not-found mt-3">
                            Пользователь не найден
                        </div>
                        <div v-else class="messenger__body-chat_list">
                            <div v-for="(user, i) in getUserList" class="messenger__body-chat" @click="getChat(user.id)">
                                <div class="messenger__body-avatar_icon">
                                    <img v-if="user.avatar" :src="user.avatar" />
                                    <img v-else src="/images/personal.svg">
                                </div>
                                <div class="messenger__body-user_info">
                                    <div class="messenger__body-user_info-name">
                                        {{ user.surname }} {{ user.name }} {{ user.patronymic }}
                                    </div>
                                    <div class="messenger__body-user_info-last-msg">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="!showUserList && currentUserChat.user && currentUserChat.user.id" class="h-100">
                        <div class="messenger__body-chat_messages">
                            <div class="messenger__body-chat_messages_in-wr">
                                <div
                                    v-for="(message, i) in chatMessages"
                                    class="messenger__body-chat_msg"
                                    :class="message.is_user_comment ? 'is_user_comment_wr' : ''"
                                >
                                    <div
                                        class="messenger__body-chat_msg_wr-in"
                                        :class="message.is_user_comment ? 'is_user_comment' : 'not_user_comment'"
                                    >
                                        <div class="messenger__body-chat_msg_info">
                                            <div>{{ message.created_at_date_formatted }}</div>
                                            <div>{{ message.created_at_time_formatted }}</div>
                                        </div>
                                        <div class="messenger__body-chat_msg_text">
                                            {{ message.content }}
                                            <!--div v-if="message.is_user_comment">
                                                <div v-if="message.status == 'read'" class="messenger__body-chat_msg-read text-primary">
                                                    <b-icon icon="check-all"></b-icon>
                                                </div>
                                                <div v-else class="messenger__body-chat_msg-read text-muted">
                                                    <b-icon icon="check"></b-icon>
                                                </div>
                                            </div-->
                                        </div>
                                        <div v-if="message.files" v-for="(file, fileIndex) in message.files" class="messenger__body-chat_msg_files">
                                            <img v-if="file.mime_type == 'pdf'" src="/images/pdf-file.svg" />
                                            <img v-else :src="file.file" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="message_files" class="messenger__body-chat_preview-file">
                            <div class="messenger__body-chat_preview-img" v-for="(file, fileIndex) in message_files">
                                <img v-if="file.mime_type == 'pdf'" src="/images/pdf-file.svg" />
                                <img v-else :src="file.file" />
                                <div class="messenger__body-chat_preview-file-x" @click="removeFile(fileIndex)">X</div>
                            </div>
                        </div>
                        <div class="d-flex messenger__body-chat_form_wr">
                            <div class="messenger__body-chat_form">
                            <textarea
                                    id="text_area"
                                    placeholder="Сообщение"
                                    v-model="content"
                                    @keyup="textarea_resize($event, 15, 2)"
                            >
                            </textarea>
                                <div id="text_area_div"></div>
                                <img
                                        class="messenger__body-chat_traced"
                                        src="/images/traced.svg"
                                        @click="sendMessage"
                                />
                            </div>
                            <div class="messenger__body-chat_file">
                                <input
                                        type="file"
                                        id="chat_files"
                                        @change="previewFile"
                                        multiple="multiple"
                                />
                                <label for="chat_files">
                                    <img src="/images/skrepka.svg" />
                                </label>
                            </div>
                        </div>
                    </div>
                    <Preloader :active="getActivePreloader" />
                    <!--div v-if="currentUserChat.user && currentUserChat.user.id">
                        <div class="messenger__body-chat_messages">
                            <div
                                    v-for="(message, i) in chatMessages"
                                    class="messenger__body-chat_msg"
                                    :class="message.is_user_comment ? 'is_user_comment' : 'not_user_comment'"
                            >
                                <div class="messenger__body-chat_msg_info">
                                    <div>{{ message.created_at_date_formatted }}</div>
                                    <div>{{ message.created_at_time_formatted }}</div>
                                </div>
                                <div class="messenger__body-chat_msg_text">
                                    {{ message.content }}
                                </div>
                            </div>
                        </div>

                        <div class="messenger__body-chat_form">
                            <input
                                    class="messenger__body-chat_input"
                                    placeholder="Сообщение"
                                    v-model="content"
                            />
                            <img
                                    class="messenger__body-chat_traced"
                                    src="/images/traced.svg"
                                    @click="sendMessage"
                            />
                        </div>
                    </div-->
                    <!--div v-if="!getUserList.length && showUserList" class="messenger__body-user-not-found mt-3">
                        Пользователь не найден
                    </div>
                    <div v-else class="messenger__body-chat_list">
                        <div v-if="">

                        </div>
                        <div v-if="chats" v-for="(chat, i) in chats" class="messenger__body-chat" @click="getChat(chat.user_id)">
                            <div class="messenger__body-avatar_icon">
                                <img v-if="chat.avatar" :src="chat.avatar" />
                                <img v-else src="/images/personal.svg">
                            </div>
                            <div class="messenger__body-user_info">
                                <div class="messenger__body-user_info-name">
                                    {{ chat.surname }} {{ chat.name }} {{ chat.patronymic }}
                                </div>
                                <div class="messenger__body-user_info-last-msg">
                                   {{ chat.last_message }}
                                </div>
                            </div>
                        </div>
                        <div v-else v-for="(user, i) in getUserList" class="messenger__body-chat" @click="getChat(user.id)">
                            <div class="messenger__body-avatar_icon">
                                <img v-if="user.avatar" :src="user.avatar" />
                                <img v-else src="/images/personal.svg">
                            </div>
                            <div class="messenger__body-user_info">
                                <div class="messenger__body-user_info-name">
                                    {{ user.surname }} {{ user.name }} {{ user.patronymic }}
                                </div>
                                <div class="messenger__body-user_info-last-msg">
                                    последнее сообщение
                                </div>
                            </div>
                        </div>
                    </div-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import {mapGetters} from "vuex";
import Preloader from '../preloader/Preloader.vue';

export default {
    components: {
        Preloader
    },
    data() {
        return {
            chats: [],
            messages: [],
            selectedChat: null,
            newMessage: '',
            users: [],
            isOpen: true,

            showUserList: false,
            showUserChat: false,

            userNickname: null,
            start: 0,
            limit: 10,

            currentUserChat: {
                user: null,
                messages: null,
            },

            chatInfo: {
                id: null,
            },

            content: null,

            chatMessages: [],

            intervalGetChatMessages: null,

            message_files: [],
        };
    },
    created() {
        //this.fetchChats();
        //this.fetchUsers();

        document.addEventListener('keydown', this.onKeyDown)
    },
    beforeDestroy() {
        document.removeEventListener('keydown', this.onKeyDown)
    },
    computed: {
        ...mapGetters({
            getIsOpen: 'messanger/getIsOpen',
            getUserList: 'messanger/getUserList',
            getChatList: 'messanger/getChatList',
            getActivePreloader: 'messanger/getActivePreloader',
        }),
    },
    methods: {
        toggleMessenger(status) {
            const app = this;

            if (status === false) {
                app.showUserList = false;
                app.userNickname = null;

                app.chatInfo.id = null;

                app.currentUserChat.user = null;
                app.currentUserChat.messages = null;

                clearInterval(app.intervalGetChatMessages);
            }

            app.$store.dispatch('messanger/setIsOpen', status);
        },
        async fetchChats() {
            try {
                const response = await axios.get('http://your-api-url/api/chats');
                this.chats = response.data;
            } catch (error) {
                console.error('Error fetching chats:', error);
            }
        },
        async fetchUsers() {
            try {
                const response = await axios.get('/api/users');
                this.users = response.data;
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        },
        async fetchMessages(chatId) {
            try {
                const response = await axios.get(`/api/chats/${chatId}/messages`);
                this.messages = response.data;
            } catch (error) {
                console.error('Error fetching messages:', error);
            }
        },
        selectChat(chat) {
            this.selectedChat = chat;
            this.fetchMessages(chat.id);
        },
        getUser(userId) {
            return this.users.find(user => user.id === userId) || { username: 'Unknown' };
        },
        acceptFilter()
        {
            const app = this;

            if (!app.userNickname) {
                //alert('Имя пользователя не передано!');
                app.$store.dispatch('messanger/setUserList', []);

                return false;
            }

            app.$store.dispatch('messanger/setMessengerFilterFields', {
                user_nickname: app.userNickname,
                main_filter: app.mainFilter,
            }).then(() => {
                /*let queryParams = app.generateQueryParams();

                app.$router.push({
                    query: queryParams,
                });*/

                app.$store.dispatch('messanger/loadUserList', {
                    limit: app.limit,
                    start: app.start,
                }).then(() => {
                    app.showUserList = true;
                });
            });
        },
        getChat(user_id) {
            const app = this;

            app.showUserList = false;

            axios.post('/api/messenger/get-chat', {
                user_id: user_id,
            }).then(response => {
                const result = response.data.data;

                if (result.code === 'ok' && result.result.success === true) {
                    app.chatInfo.id = result.result.chat_info.chat_id;

                    app.currentUserChat.user = result.result.chat_info.user;
                    app.currentUserChat.messages = result.result.chat_info.messages;

                    app.setIntervalGetChatMessages();
                } else {
                    if (result.result.message) {
                        app.$toaster.error(result.result.message);
                    } else {
                        app.$toaster.error('Ошибка получения!');
                    }
                }
            }).catch(error => {
                console.log(error.response)
            });
        },
        sendMessage()
        {
            const app = this;

            let url = '/api/messenger/store-message';

            axios.post(url, {
                chat_id: app.chatInfo.id,
                content: app.content,
                message_files: app.message_files,
            }).then(response => {
                const result = response.data.data;

                if (result.code === 'ok' && result.result.success === true) {
                    if (result.result.message) {
                        app.content = null;

                        app.getMessages(app.chatInfo.id);
                    }

                    app.message_files = [];

                    app.$toaster.success('Сообщение отправлено');
                } else {
                    if (result.result.message) {
                        app.$toaster.error(result.result.message);
                    } else {
                        app.$toaster.error('Ошибка отправки сообщения!');
                    }
                }
            }).catch(error => {
                console.log(error);
                app.$toaster.error('Ошибка отправки');
            });
        },
        getMessages() {
            const app = this;

            if (app.chatInfo && app.chatInfo.id) {
                axios.post('/api/messenger/get-messages', {
                    chat_id: app.chatInfo.id,
                }).then(response => {
                    const result = response.data.data;

                    if (
                        result.code === 'ok'
                        && result.result.success === true
                        && result.result.messages
                    ) {
                        if (result.result.messages) {
                            app.chatMessages = result.result.messages;
                        }
                    } else {
                        app.$toaster.error('Ошибка получения данных');
                    }
                }).catch(error => {
                    app.$toaster.error('Ошибка получения данных');
                });
            }
        },
        setIntervalGetChatMessages() {
            const app = this;

            if (app.chatInfo && app.chatInfo.id) {
                app.intervalGetChatMessages = setInterval(app.getMessages, 30000)
                app.$on('hook:beforeDestroy', () => clearInterval(app.intervalGetChatMessages));
            }
        },
        backToChats() {
            const app = this;

            app.chatInfo.id = null;

            app.currentUserChat.user = null;
            app.currentUserChat.messages = null;
            app.chatMessages = [];

            app.showUserList = false;
            app.userNickname = null;

            clearInterval(app.intervalGetChatMessages);
        },
        onKeyDown(e) {
            const app = this;

            if (e.keyCode == 13 && app.currentUserChat.user && app.currentUserChat.user.id) {
                app.sendMessage();
            }
        },
        textarea_resize(event, line_height, min_line_count) {
            var min_line_height = min_line_count * line_height;

            var obj = event.target;

            var div = document.getElementById(obj.id + '_div');
            div.innerHTML = obj.value;

            var obj_height = div.offsetHeight;

            if (event.keyCode == 13)
                obj_height += line_height;
            else if (obj_height < min_line_height)
                obj_height = min_line_height;

            let baseHeight = 378;

            if (obj_height < 288) {
                obj.style.height = obj_height + 'px';

                document.querySelector('.messenger__body-chat_messages').style.height = (baseHeight - parseFloat(obj.style.height.replace('px', ''))) + 'px';
            } else {
                document.querySelector('#text_area').style.overflow = 'scroll';
            }
        },
        previewFile(e) {
            const app = this;

            app.createFiles(e.target.files || e.dataTransfer.files);
        },
        createFiles(files) {
            const app = this;

            [...files].forEach(file => {
                const reader = new FileReader();

                if (file.name.lastIndexOf('.') <= 0) {
                    return;
                }

                let mime_type = file.name.slice(file.name.lastIndexOf('.') + 1);
                let checkValidMimeType = false;

                if (
                    mime_type == 'jpg'
                    || mime_type == 'jpeg'
                    || mime_type == 'png'
                    || mime_type == 'pdf'
                    || mime_type == 'doc'
                    || mime_type == 'docx'
                    || mime_type == 'xls'
                    || mime_type == 'xlsx'
                    || mime_type == 'xml'
                ) {
                    checkValidMimeType = true;
                }

                if (checkValidMimeType == false) {
                    alert('Неверный формат файла: ' + file.name);

                    return false;
                }

                const maxSizeMB = 15;
                const maxSizeBytes = maxSizeMB * 1024 * 1024;

                if (file.size > maxSizeBytes) {
                    alert('Вес файла не должен превышать ' + maxSizeMB + 'МБ: ' + file.name);

                    return false;
                }

                reader.onload = e => app.message_files.push({
                    title: file.name,
                    file: e.target.result,
                    mime_type: mime_type,
                });
                reader.readAsDataURL(file);
            });
        },
        removeFile(index) {
            const app = this;

            app.message_files.splice(index, 1);
        },
    },
    watch: {
        chatInfo: {
            immediate: true,
            deep: true,
            handler (newValue, oldValue) {
                if (newValue.id) {
                    const app = this;

                    app.getMessages();
                }
            }
        },
        getIsOpen: {
            immediate: true,
            deep: true,
            handler (newValue, oldValue) {
                if (newValue) {
                    const app = this;
                }
            }
        },
    }
};
</script>
<style src="./Messenger.css"></style>
