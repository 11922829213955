export default {
    name: "Avatar",
    data() {
        return {
            currentUser: {
                avatar: null,
            },
        }
    }
}
