import {mapGetters} from "vuex";

export default {
    name: "Preloader",
    props: ['active'],
    data() {
        return {

        }
    },
}