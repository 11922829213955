export default {
    setStampFilterFieldsMutation(state, payload)
    {
        state.stampFilter.fields.stampNumber = payload.stamp_number;
        state.stampFilter.fields.stampTitle = payload.stamp_title;
        state.stampFilter.fields.stampDeleted = payload.stamp_deleted;
    },
    setStampList(state, payload)
    {
        state.stampList = payload;
    },
    setStampListCount(state, payload) {
        state.stampListCount = payload;
    },
    deleteStampsMutation(state, payload)
    {
        if (payload.length) {
            let index = null;

            payload.forEach(function(item, i, payload) {
                //document.getElementById(`stamp-id-${item}`).checked = false;

                index = state.stampList.findIndex(stamp => stamp.id == item);

                if (index !== -1) {
                    state.stampList.splice(index, 1);
                }
            });
        }
    },
    setStampModalOpenMutation(state, payload)
    {
        state.stampModalOpen = payload;
    },
    setStampIdAndStampIndexToModalFormMutation(state, payload)
    {
        state.stamp_id = payload.stamp_id;
        state.stamp_index = payload.stamp_index;
    },
    updateStamp(state, payload)
    {
        state.stampList[payload.stamp_index].title = payload.title;
    },
    addStampToStampList(state, payload)
    {
        state.stampList.push(payload);
    },
    pushStampDataToList(state, payload)
    {
        if (payload) {
            state.stampsData.push(payload);
        }
    },
    setStampsData(state, payload)
    {
        if (payload) {
            state.stampsData = payload;
        }
    },
}
