export default {
    setDocumentFilterFieldsMutation(state, payload)
    {
        state.documentFilter.fields.documentNumber = payload.document_number;
        state.documentFilter.fields.documentTitle = payload.document_title;
        state.documentFilter.fields.documentUserName = payload.document_user_name;
        state.documentFilter.fields.documentHash = payload.document_hash;
        state.documentFilter.fields.documentClientId = payload.document_client_id;
        state.documentFilter.fields.documentCreatedAt = payload.document_created_at;
        state.documentFilter.fields.documentStatus = payload.document_status;
        state.documentFilter.fields.documentDeleted = payload.document_deleted;
        state.documentFilter.fields.documentCompanyId = payload.document_company_id;
        state.documentFilter.main_filter = payload.main_filter;
    },
    setDocumentList(state, payload)
    {
        state.documentList = payload;
    },
    setDocumentListCount(state, payload) {
        state.documentListCount = payload;
    },
    deleteDocumentsMutation(state, payload)
    {
        if (payload.length) {
            let index = null;

            payload.forEach(function(item, i, payload) {
                document.getElementById(`document-id-${item}`).checked = false;

                index = state.documentList.findIndex(document => document.id == item);

                if (index !== -1) {
                    state.documentList.splice(index, 1);
                }
            });
        }
    },
    setDocumentModalOpenMutation(state, payload)
    {
        state.documentModalOpen = payload;
    },
    setDocumentIdAndDocumentIndexToModalFormMutation(state, payload)
    {
        state.document_id = payload.document_id;
        state.document_index = payload.document_index;
    },
    updateDocument(state, payload)
    {
        state.documentList[payload.document_index].title = payload.title;
        state.documentList[payload.document_index].executor_title = payload.executor_title;
        state.documentList[payload.document_index].executor_who_signed_document = payload.executor_who_signed_document;
        state.documentList[payload.document_index].executor_job_title = payload.executor_job_title;
        state.documentList[payload.document_index].client_id = payload.client_id;
    },
    addDocumentToDocumentList(state, payload)
    {
        state.documentList.unshift({
            id: payload.id,
            client_title: payload.client_title,
            company_title: payload.company_title,
            created_at: payload.created_at,
            customer_stamp_date_time: payload.customer_stamp_date_time,
            deleted_at: payload.deleted_at,
            document_status: payload.document_status,
            executor_job_title: payload.executor_job_title,
            executor_stamp_date_time: payload.executor_stamp_date_time,
            executor_title: payload.executor_title,
            executor_who_signed_document: payload.executor_who_signed_document,
            leader: payload.leader,
            public_access: payload.public_access,
            stamp_content_data: payload.stamp_content_data,
            title: payload.title,
            user_name: payload.user_name,
            link: payload.link,
        });
    },
    updateDocumentExecutorStampDateTime(state, payload)
    {
        state.documentList[payload.document_index].executor_stamp_date_time = payload.executor_stamp_date_time;
    },
}
