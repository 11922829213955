import {mapGetters} from "vuex";

export default {
    name: "LeftNav",
    data() {
        return {
            noReaded: 0,
            links: [
                {
                    title: "Профиль",
                    href: "/",
                    icon: "/images/left-nav/profile-icon.svg",
                    name: "clientsSettings",
                },
                {
                    title: "Компании",
                    href: "/companies",
                    icon: "/images/left-nav/my-companies-icon.svg",
                    name: "companies",
                },
                {
                    title: "Документы",
                    href: "/documents",
                    icon: "/images/left-nav/my-documents-icon.svg",
                    name: "documents",
                },
                /*{
                    title: "Печати",
                    href: "/stamps",
                    icon: "/images/left-nav/my-stamps-icon.svg",
                    name: "stamps",
                },*/
                {
                    title: "Клиенты",
                    href: "/clients",
                    icon: "/images/left-nav/my-clients-icon.svg",
                    name: "clients",
                },
                /*{
                    title: "Транзакции",
                    href: "/transactions",
                    icon: "receipt",
                    name: "transactions",
                },*/
                {
                    title: "Транзакции",
                    href: "/transactions",
                    icon: "/images/left-nav/transactions-icon.svg",
                    name: "transactions",
                },
                {
                    title: "Поддержка",
                    href: "/tickets",
                    icon: "/images/left-nav/support-icon.svg",
                    name: "tickets",
                },
            ],
            isActive: false,
            status: false,
            deal_board_view: null,
            currentUser: {
                avatar: null,
                profile_type: 'web-dev',
            },
        }
    },
    created() {
        document.getElementsByTagName('body')[0].addEventListener('click', e => {
            if (!e.target.closest('.profile-popover') || e.target.classList.contains('profile__popover-item-link-close') === true) {
                this.$root.$emit('bv::hide::popover')
            }
        });

        document.querySelectorAll('.profile__popover-item-link').forEach(el =>
            el.addEventListener("click", (e) => this.$root.$emit('bv::hide::popover'))
        );
    },
    mounted() {
        const app = this;

        app.isActive = localStorage.getItem('token') ?? false;
    },
    computed: {
        ...mapGetters({
            getCurrentMenuItem: 'navigation/getCurrentMenuItem',
            //getDealBoardView: 'systemData/getDealBoardView',
        }),
    },
    methods: {
        logout()
        {
            const app = this;

            app.$store.dispatch('currentUser/logoutUser');
        },
        routePush(link)
        {
            if (link.name != 'statistic') {
                this.$router.push(link.href);
            }
        },
        showMessanger(status)
        {
            this.$store.dispatch('messanger/setMessangerShowed', status);
        },
        checkUserReadAccess(link)
        {
            const app = this;

            let result = false;

            if (app.currentUser) {
                if (app.currentUser.roles) {
                    let permissionIndex = null;

                    app.currentUser.roles.forEach(function(role, index, array) {
                        permissionIndex = role.permission_.findIndex(permission => permission.name == link.name);

                        if (permissionIndex >= 0) {
                            if (role.permission_[permissionIndex].read == true) {
                                result = true;

                                return result;
                            }
                        }
                    });
                }
            }

            return result;
        },
    },
    watch: {
        getNoReaded: {
            immediate: true,
            deep: false,
            handler(newValue, oldValue) {
                this.noReaded = newValue;
            }
        },
    }
}
