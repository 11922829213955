import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from "axios";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import Toaster from 'v-toaster'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'v-toaster/dist/v-toaster.css';
import Vuelidate from "vuelidate";
import VMask from "v-mask";
import store  from './store/index.js';

Vue.config.productionTip = false
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Vuelidate);
Vue.use(VMask);
Vue.use(Toaster, {timeout: 5000});

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");

new Vue({
    router,
    render: h => h(App),
    store,
}).$mount('#app')
