<template>
  <div class="page-wr"><router-view/></div>
</template>

<script>
export default {
  name: "GuestLayout"
}
</script>

<style scoped>

</style>