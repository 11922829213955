export default {
    getTransactionList: (state) => {
        return state.transactionList;
    },
    getTransactionListCount: (state) => {
        return state.transactionListCount;
    },
    getTransactionMainFilter: (state) => {
        return state.transactionFilter.main_filter;
    },
    getTransactionFilterFields: (state) => {
        return state.transactionFilter.fields;
    },
    getSupplementAmount: (state) => {
        return state.supplement_amount;
    },
}
