export default {
    setClientFilterFieldsMutation(state, payload)
    {
        state.clientFilter.fields.clientNumber = payload.client_number;
        state.clientFilter.fields.clientTitle = payload.client_title;
        state.clientFilter.fields.clientPhoneNumber = payload.client_phone_number;
        state.clientFilter.fields.clientDeleted = payload.client_deleted;
        state.clientFilter.main_filter = payload.main_filter;
    },
    setClientList(state, payload)
    {
        state.clientList = payload;
    },
    setClientListCount(state, payload) {
        state.clientListCount = payload;
    },
    deleteClientsMutation(state, payload)
    {
        if (payload.length) {
            let index = null;

            payload.forEach(function(item, i, payload) {
                //document.getElementById(`client-id-${item}`).checked = false;

                index = state.clientList.findIndex(client => client.id == item);

                if (index !== -1) {
                    state.clientList.splice(index, 1);
                }
            });
        }
    },
    setClientModalOpenMutation(state, payload)
    {
        state.clientModalOpen = payload;
    },
    setClientIdAndClientIndexToModalFormMutation(state, payload)
    {
        state.client_id = payload.client_id;
        state.client_index = payload.client_index;
    },
    addClientToClientList(state, payload)
    {
        state.clientList.push(payload);
    },
    updateClient(state, payload)
    {
        state.clientList[payload.client_index].name_s = payload.name;
        state.clientList[payload.client_index].surname_s = payload.surname;
        state.clientList[payload.client_index].patronymic_s = payload.patronymic;
        state.clientList[payload.client_index].email_s = payload.email;
    },
}
