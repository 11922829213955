export default {
    getCompanyList: (state) => {
        return state.companyList;
    },
    getCompanyListCount: (state) => {
        return state.companyListCount;
    },
    getCompanyMainFilter: (state) => {
        return state.companyFilter.main_filter;
    },
    getCompanyFilterFields: (state) => {
        return state.companyFilter.fields;
    },
    getCompanyModalOpen: (state) => {
        return state.companyModalOpen;
    },
    getCompanyIdAndCompanyIndex: (state) => {
        return {
            company_id: state.company_id,
            company_index: state.company_index
        };
    },
}
