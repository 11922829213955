export default {
    getStampList: (state) => {
        return state.stampList;
    },
    getStampListCount: (state) => {
        return state.stampListCount;
    },
    getStampMainFilter: (state) => {
        return state.stampFilter.main_filter;
    },
    getStampFilterFields: (state) => {
        return state.stampFilter.fields;
    },
    getStampModalOpen: (state) => {
        return state.stampModalOpen;
    },
    getStampIdAndStampIndex: (state) => {
        return {
            stamp_id: state.stamp_id,
            stamp_index: state.stamp_index
        };
    },
    getStampsData: (state) => {
        return state.stampsData;
    },
}
