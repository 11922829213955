import router from "../../../router";
import axios from "axios";

export default {
    loadCompanyList({ commit, dispatch }, payload)
    {
        return new Promise((resolve, reject) => {
            let filter_fields = this.getters['companies/getCompanyFilterFields'];
            let main_filter = this.getters['companies/getCompanyMainFilter'];

            if (!payload['preloader_disable'] || payload['preloader_disable'] == false) {
                dispatch('pagePreloader/setPagePreloaderIsActive', true, {root: true});

                commit('setCompanyList', []);
            }

            if (!main_filter && router.currentRoute.query.main_filter) {
                main_filter = router.currentRoute.query.main_filter;

                commit('setCompanyFilterFieldsMutation', {main_filter: router.currentRoute.query.main_filter});
            }

            if (!filter_fields.companyNumber && router.currentRoute.query.company_number) {
                filter_fields.companyNumber = router.currentRoute.query.company_number;

                commit('setCompanyFilterFieldsMutation', {field_name: 'companyNumber', field_value: router.currentRoute.query.company_number})
            }

            if (!filter_fields.companyTitle && router.currentRoute.query.company_title) {
                filter_fields.companyTitle = router.currentRoute.query.company_title;

                commit('setCompanyFilterFieldsMutation', {field_name: 'companyTitle', field_value: router.currentRoute.query.company_title})
            }

            if (!filter_fields.companyType && router.currentRoute.query.company_type) {
                filter_fields.companyType = router.currentRoute.query.company_type;

                commit('setCompanyFilterFieldsMutation', {field_name: 'companyType', field_value: router.currentRoute.query.company_type})
            }

            if (!filter_fields.companyStatus && router.currentRoute.query.company_status) {
                filter_fields.companyStatus = router.currentRoute.query.company_status;

                commit('setCompanyFilterFieldsMutation', {field_name: 'companyStatus', field_value: router.currentRoute.query.company_status})
            }

            payload['filter_fields'] = filter_fields;
            payload['main_filter'] = main_filter;

            axios.post('/api/company/list', payload).then((response) => {
                const result = response.data.data;

                if (response.data.data.code != 'ok')
                {
                    resolve({
                        error: true,
                        error_msg: 'Server response code not ok!',
                    });

                    return;
                }

                if (!response.data.data.result) {
                    resolve({
                        error: true,
                        error_msg: 'Result is empty!',
                    });

                    return;
                }

                dispatch('pagePreloader/setPagePreloaderIsActive', false, {root: true});

                commit('setCompanyList', response.data.data.result.companies);
                commit('setCompanyListCount', response.data.data.result.companies_count);

                resolve(true);
            }).catch((error) => {
                reject(false);
            });
        });
    },
    setCompanyFilterFields({ commit }, payload)
    {
        commit('setCompanyFilterFieldsMutation', payload);
    },
    deleteCompanies({ commit }, payload)
    {
        return new Promise((resolve, reject) => {
            axios.post('/api/company/delete-companies', {
                ids: payload
            }).then(response => {
                const result = response.data.data;

                if (response.data.data.result.success !== true) {
                    resolve(false);
                }

                commit('deleteCompaniesMutation', payload);

                resolve(true);
            }).catch(error => {
                reject(error);
            });
        });
    },
    setCompanyModalOpen({commit}, payload)
    {
        commit('setCompanyModalOpenMutation', payload);
    },
    setCompanyIdAndCompanyIndexToModalForm({commit}, payload)
    {
        commit('setCompanyIdAndCompanyIndexToModalFormMutation', payload);
    },
    addCompanyToCompanyList({commit}, payload)
    {
        commit('addCompanyToCompanyList', payload);
    },
    updateCompany({ commit }, payload)
    {
        commit('updateCompany', payload);
    },
    updateCompanyCountEmployers({ commit }, payload)
    {
        commit('updateCompanyCountEmployers', payload);
    },
}
