import {mapGetters} from "vuex";
import axios from "axios";

export default {
    name: 'ModalTariffListForm',
    data() {
        return {
            current_tariff: null,
        }
    },
    computed: {
        ...mapGetters({
            getUser: 'currentUser/getUser',
            getTariffList: 'system/getTarrifList',
        }),
    },
    methods: {
        buyServicePackage(tariff_id) {
            const app = this;

            axios.post('/api/tariff/buy-service-package', {
                user_id: app.getUser.id,
                tariff_id: tariff_id,
            }).then(response => {
                const result = response.data.data;

                if (result.code === 'ok' && result.result.success === true) {
                    if (result.result.wallet) {
                        app.$store.dispatch('currentUser/setUserWalletSignsBox', result.result.wallet.signs_box);
                        app.$store.dispatch('currentUser/setUserWalletBalance', result.result.wallet.balance);
                    }

                    app.toggleModalConfirmBuyServicePackageForm(false);

                    app.$toaster.success('Пакет успешно куплен');
                } else {
                    if (result.result.message) {
                        app.$toaster.error(result.result.message);
                    } else {
                        app.$toaster.error('Ошибка покупки!');
                    }
                }
            }).catch(error => {
                console.log(error);
                app.$toaster.error('Ошибка покупки');
            });
        },
        toggleModalConfirmBuyServicePackageForm(status = false, tariff = null)
        {
            const app = this;

            if (status === true) {
                if (tariff) {
                    app.current_tariff = tariff;
                }

                app.$refs['modal-confirm-buy-service-package-form'].show();
            } else {
                app.current_tariff = null;

                app.$refs['modal-confirm-buy-service-package-form'].hide();
            }
        },
    },
}