import {mapGetters} from "vuex";
import ModalDocumentForm from "@/components/template/header/modal-document-form/ModalDocumentForm.vue";
import ModalSupplementForm from "@/components/profile-settings/modal-supplement-form/ModalSupplementForm.vue";
import ModalTariffListForm from "@/components/profile-settings/modal-tariff-list-form/ModalTariffListForm.vue";
import axios from "axios";

export default {
    name: "Header",
    components: {
        ModalDocumentForm,
        ModalSupplementForm,
        ModalTariffListForm,
    },
    data() {
        return {
            notifications: [],
        }
    },
    computed: {
        ...mapGetters({
            currentUser: 'currentUser/getUser',
            getNewNitificationList: 'currentUser/getNewNitificationList',
            getNotificationCounter: 'currentUser/getNotificationCounter',
            getSupplementAmount: 'transactions/getSupplementAmount',
        }),
    },
    mounted() {
        const app = this;

        app.$store.dispatch('currentUser/getNewNotificationCount');

        setInterval(() => {
            app.$store.dispatch('currentUser/getNewNotificationCount');
        }, 60000);
    },
    methods: {
        toggleDownloadDocumnetForm(state = false)
        {
            this.$store.dispatch('modalDownloadDocumentForm/toggleFormState', state);
        },
        showModalNoticeForm()
        {
            const app = this;

            app.$store.dispatch('currentUser/setNotificationList', {
                notification_list: [],
            }).then(() => {
                axios.post('/api/notification/list').then(response => {
                    const result = response.data.data;

                    if (result.code === 'ok' && result.result.success === true) {
                        let noty_list = [];

                        if (result.result.notifications && result.result.notifications.length) {
                            noty_list = result.result.notifications;
                        }

                        app.$store.dispatch('currentUser/setNotificationList', {
                            notification_list: noty_list,
                        }).then(() => {
                            if (noty_list.length) {
                                setTimeout(() => {
                                    axios.post('/api/notification/set-readed', {
                                        notification_list: noty_list,
                                    }).then(response => {
                                        app.$store.dispatch('currentUser/getNewNotificationCount');
                                    });
                                }, 1000);
                            }
                        });
                    } else {
                        if (result.result.message) {
                            app.$toaster.error(result.result.message);
                        } else {
                            app.$toaster.error('Ошибка получения данных!');
                        }
                    }
                }).catch(error => {
                    console.log(error);
                    app.$toaster.error('Ошибка получения');
                });
            });

            app.$refs['modal-notice-form'].show();
        },
        hideModalNoticeForm()
        {
            const app = this;

            app.notifications = [];

            app.$refs['modal-notice-form'].hide();
        },
        toggleModalSupplementForm()
        {
            const app = this;

            app.$refs['modal-supplement-form'].show();
        },
        hideModalSupplementForm()
        {
            const app = this;

            app.$store.dispatch('transactions/setSupplementAmount', null);

            app.$refs['modal-supplement-form'].hide();
        },
        saveSupplementForm()
        {
            const app = this;

            let url = '/api/wallet/supplement-balance';

            axios.post(url, {
                id: app.currentUser.wallet.id,
                amount: app.getSupplementAmount,
            }).then(response => {
                const result = response.data.data;

                if (result.code === 'ok' && result.result.success === true) {
                    window.location.replace(result.result.link);
                } else {
                    if (result.result.message) {
                        app.$toaster.error(result.result.message);
                    } else {
                        app.$toaster.error('Ошибка пополнения баланса!');
                    }
                }
            }).catch(error => {
                console.log(error);
                app.$toaster.error('Ошибка пополнения');
            });
        },
        toggleTarifModal(status = false)
        {
            if (status === true) {
                this.$refs['modal-tarif-list'].show();

                this.getTariffSettings();
            } else {
                this.$refs['modal-tarif-list'].hide();
            }
        },
    }
}
