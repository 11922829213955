import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    stampList: [],
    stampListCount: null,
    stampFilter: {
        main_filter: null,
        fields: {
            stampNumber: null,
            stampTitle: null,
            stampDeleted: null,
        },
    },
    stampModalOpen: false,
    stamp_id: null,
    stamp_index: null,
    stampsData: [],
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
}
