export default {
    setUserMutation(state, payload)
    {
        state.user = payload;
    },
    updateUser(state, payload)
    {
       if (payload) {
           state.user.name = payload.name;
           state.user.surname = payload.surname;
           state.user.patronymic = payload.patronymic;
           state.user.email = payload.email;

           if (payload.avatar) {
               state.user.avatar = payload.avatar;
           }
       }
    },
    updateUserPassportInfo(state, payload)
    {
        if (payload) {
            if (payload.passport_image) {
                state.user.passport_image = payload.passport_image;
            }

            if (payload.passport_registration_image) {
                state.user.passport_registration_image = payload.passport_registration_image;
            }
        }
    },
    setUserTariff(state, payload)
    {
        if (payload) {
            if (state.user.tariff) {
                state.user.tariff.tariff_id = payload.tariff_id;
                state.user.tariff.title = payload.title;
                state.user.tariff.cost = payload.cost;
                state.user.tariff.cost_per_document = payload.cost_per_document;
                state.user.tariff.amount_documents = payload.amount_documents;
            } else {
                state.user.tariff = {
                    id: payload.id,
                    tariff_id: payload.tariff_id,
                    title: payload.title,
                    cost: payload.cost,
                    cost_per_document: payload.cost_per_document,
                    amount_documents: payload.amount_documents,
                };
            }
        }
    },
    setUserWalletBalance(state, payload)
    {
        if (state.user.wallet.balance) {
            state.user.wallet.balance = payload;
        }
    },
    setUserWalletSignsBox(state, payload)
    {
        if (state.user.wallet.signs_box) {
            state.user.wallet.signs_box = payload;
        }
    },
    setNotificationCounter(state, payload)
    {
        state.notificationCounter = payload;
    },
    setNotificationList(state, payload)
    {
        //console.log(payload);

        if (payload.notification_list && payload.notification_list.length) {
            state.notifications = payload.notification_list;
        } else {
            state.notifications = [];
        }
    },
    setRequestConnectionModalOpenMutation(state, payload)
    {
        state.requestConnectionModalOpen = payload;
    },
    setCompaniesCountMutation(state, payload)
    {
        if (state.user.companies_count) {
            state.user.companies_count = payload;
        }
    },
}
