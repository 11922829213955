export default {
    setCompanyFilterFieldsMutation(state, payload)
    {
        state.companyFilter.fields.companyNumber = payload.company_number;
        state.companyFilter.fields.companyTitle = payload.company_title;
        state.companyFilter.fields.companyType = payload.company_type;
        state.companyFilter.fields.companyStatus = payload.company_status;
        state.companyFilter.main_filter = payload.main_filter;
    },
    setCompanyList(state, payload)
    {
        state.companyList = payload;
    },
    setCompanyListCount(state, payload) {
        state.companyListCount = payload;
    },
    deleteCompaniesMutation(state, payload)
    {
        if (payload.length) {
            let index = null;

            payload.forEach(function(item, i, payload) {
                //document.getElementById(`company-id-${item}`).checked = false;

                index = state.companyList.findIndex(company => company.id == item);

                if (index !== -1) {
                    state.companyList.splice(index, 1);
                }
            });
        }
    },
    setCompanyModalOpenMutation(state, payload)
    {
        state.companyModalOpen = payload;
    },
    setCompanyIdAndCompanyIndexToModalFormMutation(state, payload)
    {
        state.company_id = payload.company_id;
        state.company_index = payload.company_index;
    },
    addCompanyToCompanyList(state, payload)
    {
        state.companyList.push(payload);
    },
    updateCompany(state, payload)
    {
        state.companyList[payload.company_index].title = payload.title;
        state.companyList[payload.company_index].type = payload.type;
    },
    updateCompanyCountEmployers(state, payload)
    {
        state.companyList[state.company_index].count_employers = payload;
    }
}
