import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import navigation from "./modules/navigation";
import documents from "./modules/documents";
import stamps from "./modules/stamps";
import clients from "./modules/clients";
import currentUser from "./modules/current-user";
import companies from "./modules/companies";
import transactions from "./modules/transactions";
import tickets from "./modules/tickets";
import moreCompanies from "./modules/more-companies";
import moreDocuments from "./modules/more-documents";
import modalDownloadDocumentForm from "./modules/modal-download-document-form";
import system from "./modules/system";
import statistics from "./modules/statistics";
import messanger from "./modules/messenger";
import pagePreloader from "./modules/page-preloader";

export default new Vuex.Store({
    modules: {
        navigation,
        documents,
        stamps,
        clients,
        currentUser,
        companies,
        transactions,
        tickets,
        moreCompanies,
        moreDocuments,
        modalDownloadDocumentForm,
        system,
        statistics,
        messanger,
        pagePreloader,
    }
});
