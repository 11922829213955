import router from "../../../router";
import axios from "axios";

export default {
    loadStampList({ commit }, payload)
    {
        return new Promise((resolve, reject) => {
            let filter_fields = this.getters['stamps/getStampFilterFields'];
            let main_filter = this.getters['stamps/getStampMainFilter'];

            if (!main_filter && router.currentRoute.query.main_filter) {
                main_filter = router.currentRoute.query.main_filter;

                commit('setStampFilterFieldsMutation', {main_filter: router.currentRoute.query.main_filter});
            }

            if (!filter_fields.stampNumber && router.currentRoute.query.stamp_number) {
                filter_fields.stampNumber = router.currentRoute.query.stamp_number;

                commit('setStampFilterFieldsMutation', {field_name: 'stampNumber', field_value: router.currentRoute.query.stamp_number})
            }

            if (!filter_fields.stampTitle && router.currentRoute.query.stamp_title) {
                filter_fields.stampTitle = router.currentRoute.query.stamp_title;

                commit('setStampFilterFieldsMutation', {field_name: 'stampTitle', field_value: router.currentRoute.query.stamp_title})
            }

            if (!filter_fields.stampDeleted && router.currentRoute.query.stamp_deleted) {
                filter_fields.stampDeleted = router.currentRoute.query.stamp_deleted;

                commit('setStampFilterFieldsMutation', {field_name: 'stampDeleted', field_value: router.currentRoute.query.stamp_deleted})
            }

            payload['filter_fields'] = filter_fields;
            payload['main_filter'] = main_filter;

            axios.post('/api/stamp/client-list', payload).then((response) => {
                const result = response.data.data;

                if (response.data.data.code != 'ok')
                {
                    resolve({
                        error: true,
                        error_msg: 'Server response code not ok!',
                    });

                    return;
                }

                if (!response.data.data.result) {
                    resolve({
                        error: true,
                        error_msg: 'Result is empty!',
                    });

                    return;
                }

                commit('setStampList', response.data.data.result.stamps);
                commit('setStampListCount', response.data.data.result.stamps_count);

                resolve(true);
            }).catch((error) => {
                reject(false);
            });
        });
    },
    setStampFilterFields({ commit }, payload)
    {
        commit('setStampFilterFieldsMutation', payload);
    },
    deleteStamps({ commit }, payload)
    {
        return new Promise((resolve, reject) => {
            axios.post('/api/stamp/delete-stamps', {
                ids: payload
            }).then(response => {
                const result = response.data.data;

                if (response.data.data.result.success !== true) {
                    resolve(false);
                }

                commit('deleteStampsMutation', payload);

                resolve(true);
            }).catch(error => {
                reject(error);
            });
        });
    },
    setStampModalOpen({commit}, payload)
    {
        commit('setStampModalOpenMutation', payload);
    },
    setStampIdAndStampIndexToModalForm({commit}, payload)
    {
        commit('setStampIdAndStampIndexToModalFormMutation', payload);
    },
    updateStamp({ commit }, payload)
    {
        commit('updateStamp', payload);
    },
    addStampToStampList({commit}, payload)
    {
        commit('addStampToStampList', payload);
    },
    saveStampData({ commit }, payload)
    {
        const app = this;

        return new Promise((resolve, reject) => {
            axios.post('/api/stamp/save-stamp-data', payload).then((response) => {
                const result = response.data.data;

                if (result.result.success !== true) {
                    resolve(result);

                    return;
                }

                resolve(result);
            }).catch(error => {
                reject(error);
            });
        });
    },
    pushStampDataToList({ commit }, payload)
    {
        commit('pushStampDataToList', payload);
    },
    setStampsData({commit}, payload)
    {
        commit('setStampsData', payload);
    },
    generateStampExample({ commit }, payload)
    {
        const app = this;

        return new Promise((resolve, reject) => {
            axios.post('/api/stamp/generate-stamp-example', payload).then((response) => {
                const result = response.data.data;

                if (result.result.success !== true) {
                    resolve(result);

                    return;
                }

                resolve(result);
            }).catch(error => {
                reject(error);
            });
        });
    },
}
