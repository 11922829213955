export default {
    name: 'ModalSupplementForm',
    data() {
        return {
            supplement_amount: null,
        }
    },
    methods: {
        setSupplementAmount(event)
        {
            const app = this;

            app.$store.dispatch('transactions/setSupplementAmount', event.target.value);
        },
    },
}
