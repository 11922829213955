import axios from "axios";

export default {
    name: "Autocomplete",
    props: ['dealIdProp', 'dealTitleProp', 'action', 'returnType', 'id', 'title', 'dataBlockPlace'],
    data() {
        return {
            inputData: null,
            showLoader: false,
            showResults: false,
            placeHolderText: 'Поиск по номеру телефона',
            dealList: [],
            dealId: null,
            dealTitle: null,
            returnData: [],
            dataList: [],
        }
    },
    computed: {
        getInputData() {
            if (this.action) {
                return this.title;
            } else {
                if (!this.dealIdProp && !this.dealTitleProp) {
                    return '';
                }

                return this.dealIdProp + ' ' + this.dealTitleProp;
            }
        }
    },
    methods: {
        blurAutocomplete() {
            setTimeout(() => {
                this.dealList = [];
                this.dataList = [];
            }, 500);
        },
        makeSearch() {
            const app = this;

            app.showLoader = true;

            let axios_url = '/api/tasks/get-deals';
            let axios_data = {
                system_id: app.$store.state.currentUser.user.system_id,
                user_id: app.$store.state.currentUser.user.id,
                deal_data: app.inputData,
            }

            if (app.action) {
                axios_url = app.action;

                axios_data = {
                    system_id: app.$store.state.currentUser.user.system_id,
                    user_id: app.$store.state.currentUser.user.id,
                    input_data: app.inputData,
                    data_block_place: app.dataBlockPlace ?? null,
                }

                app.dataList = [];
            }

            axios.post(axios_url, axios_data).then(response => {
                const result = response.data.data;

                if (app.action) {
                    if (result.code === 'ok' && result.result.success === true) {
                        if (result.result.data) {
                            /*result.result.data.forEach((item, i, arr) => {
                                app.dataList.push(item);
                            });*/

                            app.dataList = result.result.data;
                        } else {
                            app.dataList = [];
                        }
                    } else {
                        app.dataList = [];
                    }
                } else {
                    if (result.code === 'ok' && result.result.success === true) {
                        if (result.result.deals) {
                            app.dealList = result.result.deals;
                        } else {
                            app.dealList = [];
                        }
                    } else {
                        app.dealList = [];
                    }
                }
            }).catch(error => {
                app.$toaster.error('Ошибка получения данных');
            }).finally(() => {
                app.showLoader = false;
            });
        },
        selectDeal(deal)
        {
            const app = this;

            app.inputData = deal.id + ' ' + deal.title;
            app.dealId = deal.id;
            app.dealTitle = deal.title;

            app.dealList = [];
        },
        selectData(data)
        {
            const app = this;

            app.returnData = data;

            app.inputData = data.title;

            app.dataList = [];
        },
    },
    watch: {
        dealId: {
            handler(newValue, oldValue) {
                this.$emit('updateParentData', {
                    deal_id: newValue,
                });
            }
        },
        dealTitle: {
            handler(newValue, oldValue) {
                this.$emit('updateParentData', {
                    deal_title: newValue,
                });
            }
        },
        returnData: {
            handler(newValue, oldValue) {
                this.$emit('updateParentData', newValue);
            }
        },
        getInputData: {
            immediate: true,
            deep: true,
            handler(newValue, oldValue) {
                this.inputData = newValue;
            }
        },
        inputData: {
            handler(newValue, oldValue) {
                this.$emit('updatePhoneNumber', newValue);
            }
        },
        /*dealIdProp: {
            handler(newValue, oldValue) {
                console.log(newValue);
            }
        },
        dealTitle: {
            handler(newValue, oldValue) {
                console.log(newValue);
            }
        }*/
    }
}
