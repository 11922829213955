import { render, staticRenderFns } from "./InformationModal.vue?vue&type=template&id=0827f72f&scoped=true"
import script from "./InformationModal.js?vue&type=script&lang=js&external"
export * from "./InformationModal.js?vue&type=script&lang=js&external"
import style0 from "./InformationModal.css?vue&type=style&index=0&id=0827f72f&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0827f72f",
  null
  
)

export default component.exports