export default {
    getIsOpen: (state) => {
        return state.isOpen;
    },
    getMessengerMainFilter: (state) => {
        return state.messengerFilter.main_filter;
    },
    getMessengerFilterFields: (state) => {
        return state.messengerFilter.fields;
    },
    getUserList: (state) => {
        return state.userList;
    },
    getChatList: (state) => {
        return state.chatList;
    },
    getActivePreloader: (state) => {
        return state.activePreloader;
    },
}
