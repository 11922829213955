import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    isOpen: false,

    messengerFilter: {
        main_filter: null,
        fields: {
            userNickname: null,
        },
    },

    chatList: [],

    userList: [],

    activePreloader: false,
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
}
